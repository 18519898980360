import React, {useMemo, useRef} from 'react';
import {
  StatusBar,
  View,
  Platform,
  // Image,
  // Linking,
  // ImageBackground,
} from 'react-native';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {NavigationContainer} from '@react-navigation/native';
import {SafeAreaView, SafeAreaProvider} from 'react-native-safe-area-context';
import MainNav from './main-navigation';
import theme from '@style';
import {linking, routes} from './route';
import {
  getUUID,
  getUrlParams,
  navigationRef,
  envConfig,
  useResponsiveDimensions,
  toSports,
  goTo,
  // goTo,
} from '@utils';
import globalStore, {SportsInfo} from './services/global.state';
import {BasicObject, SafeAny} from '@types';
import {ToastType, useConfirm, useToast} from '@basicComponents/modal';
import {
  getBalance,
  getMessageNotReadCount,
  postReadMessage,
} from '@services/global.service';
import {debounceTime, Subject, takeUntil, throttleTime} from 'rxjs';
import {
  // checkPops,
  // checkPop,
  dailyRecord,
  initPush,
  setToken,
  setUserInfo,
  setVisitor,
  // IListEle,
  // tokenRoutes,
} from './app.service';
import {useVersionModal} from './common-pages/hooks/versionmodal.hooks';
import {useTranslation} from 'react-i18next';
import {useLanguageModal} from './components/business/language';
import {defaultChannel} from './config';
// import {Overlay} from '@rneui/themed';
// import {NativeTouchableOpacity} from './components/basic/touchable-opacity';
// import {goToUrl} from '@/common-pages/game-navigate';
// import PromotionModal from '@/components/business/promotion-modal';
import {ParabolaProvider} from './components/basic/parabola';
// const isListPop = true; // 是否使用多活动弹窗
import {setupAdjust} from './utils/adjust';
import {getGameURL, getSportGames} from './pages/home/home.service';
import {useDebounceFn} from 'ahooks';
import {SportItem} from './pages/home/home.type';
import i18n from './i18n';
import SportModal from './components/business/sport-modal';
import {showToast} from './utils/toast';
import Spin from './components/v2/basic/spin';
import {mainPageList} from './main-nav.config';
import DetailNavTitle from './components/business/detail-nav-title';

type SportsModalProps = {
  popVisible: boolean;
  setPopVisible: React.Dispatch<React.SetStateAction<boolean>>;
  list: SportItem[];
  getSportURL: Function;
};

const notYetWarning = {
  type: ToastType.warning,
  message: i18n.t('warning.unopen'),
};
setVisitor(getUUID());

declare var CodePush: any;
declare var AppWithCodePush: any;
if (globalStore.isAndroid) {
  CodePush = require('react-native-code-push');
}
const Stack = createStackNavigator();
const params = getUrlParams();

function App(): JSX.Element {
  if (params.channel) {
    globalStore.channel = params.channel;
  }
  if (params.code) {
    localStorage.setItem('invitationCode', params.code);
  }
  const appDistory = new Subject<boolean>();
  const {i18n} = useTranslation();
  const routeNameRef = React.useRef<null | string>();
  const remoteBundleRef = React.useRef<null | SafeAny>();
  const downloadLock = React.useRef<boolean>(false);
  const [currentRouteName, setCurrentRouteName] = React.useState('');
  const [globalLoading, setGlobalLoading] = React.useState(false);
  const {renderModal: renderDownloadToast, show: downloadToastShow} = useToast({
    backDropClose: false,
    delayClose: 5000,
  });
  // const {height: screenHeight, width: screenWidth} = useResponsiveDimensions();
  const {height: screenHeight} = useResponsiveDimensions();
  const lanModalStatus = React.useRef(false);

  const initChat = () => {
    const chatModule = require('@components/chat');
    const freshchatConfig = new chatModule.FreshchatConfig(
      '2723cbe3-2d77-49a8-9aee-93f9f8a97517',
      'a56bcd20-e428-4b14-b2b3-ed7745e6d6f2',
    );
    freshchatConfig.domain = 'msdk.freshchat.com';
    chatModule.Freshchat.init(freshchatConfig);
  };

  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm(
    'Update Now',
    'Later',
  );

  const [popSports, setPopSports] = React.useState(false);
  const [sports, setSports] = React.useState<SportItem[]>([]);
  // 如果sports有设置在主页的情况下，该字段会用于缓存只有一个体育页面的情况
  const mainPageSportsInfo = useRef({
    length: 0,
    name: '',
    url: '',
    type: '',
  });

  const goToSports = (type: string, name: string, url: string) => {
    if (type === 'betby_sport') {
      mainPageSportsInfo.current.type = type;
      goTo('BetBy');
      setGlobalLoading(false);
      return;
    }
    const foundSportsMainPage = mainPageList.find(
      item => item.name === 'Sports',
    );
    if (foundSportsMainPage) {
      mainPageSportsInfo.current.type = type;
      mainPageSportsInfo.current.name = name;
      mainPageSportsInfo.current.url = url;
      goTo('Sports', {
        name,
        url,
      });
    } else {
      toSports(name, url);
    }
  };

  const getSports = (sportsInfo?: SportsInfo) => {
    if (!globalStore.token) {
      goTo('Login');
      mainPageSportsInfo.current = {
        length: 0,
        name: '',
        url: '',
        type: '',
      };
      return;
    }
    if (sportsInfo) {
      goToSports(sportsInfo.type, sportsInfo.name, sportsInfo.url);
      return;
    }
    if (mainPageSportsInfo.current.length !== 1) {
      setGlobalLoading(true);
      getSportGames()
        .then(res => {
          if (!res || !res.length) {
            globalStore.globalTotal.next(notYetWarning);
            setGlobalLoading(false);
            return;
          }
          if (res.length > 1) {
            setSports(res);
            setPopSports(true);
            setGlobalLoading(false);
          } else {
            const foundSportsMainPage = mainPageList.find(
              item => item.name === 'Sports',
            );
            if (foundSportsMainPage) {
              mainPageSportsInfo.current.length = 1;
            }
            getSportURL(res[0].moduleType);
          }
        })
        .catch(e => {
          console.error('error', e);
          setGlobalLoading(false);
        });
    } else {
      goToSports(
        mainPageSportsInfo.current.type,
        mainPageSportsInfo.current.name,
        mainPageSportsInfo.current.url,
      );
    }
  };

  const {run: getSportURL} = useDebounceFn((gametype: string) => {
    if (gametype === 'betby_sport') {
      goToSports(gametype, '', '');
      return;
    }
    setGlobalLoading(true);
    getGameURL(gametype)
      .then((url: string) => {
        setPopSports(false);
        goToSports(
          gametype,
          gametype.split('_')[0].toUpperCase() + ' Sports',
          url,
        );
      })
      .catch(e => {
        globalStore.globalTotal.next(notYetWarning);
        console.error('error', e);
      })
      .finally(() => setGlobalLoading(false));
  });

  /** 全局订阅 */
  const globalSubscriptions = () => {
    globalStore.globalLoading.pipe(takeUntil(appDistory)).subscribe(bool => {
      setGlobalLoading(bool);
    });
    globalStore.globalTotal.pipe(takeUntil(appDistory)).subscribe(config => {
      // config.message = config.message;
      // toastShow(config);
      showToast(config.type, config.message, config.tip);
    });
    globalStore.tokenSubject.pipe(takeUntil(appDistory)).subscribe(token => {
      if (!token) {
        globalStore.notificationSubject.next({
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        });
        return;
      }
      setTimeout(() => {
        globalStore.updateAmount.next({});
      }, 100);
    });
    globalStore.refreshNotification
      .pipe(debounceTime(500), takeUntil(appDistory))
      .subscribe(() => {
        getMessageNotReadCount().then(countInfo =>
          globalStore.notificationSubject.next(countInfo),
        );
      });
    globalStore.readNotification
      .pipe(takeUntil(appDistory))
      .subscribe(({messageId, messageType}) => {
        postReadMessage(messageId, messageType).then(() => {
          globalStore.refreshNotification.next();
        });
      });
    globalStore.updateAmount
      .pipe(throttleTime(200), takeUntil(appDistory))
      .subscribe(({gameType}) => {
        globalStore.token &&
          getBalance(gameType).then(res => {
            const {totalBalance} = res;
            const amount = totalBalance;
            globalStore.setAmount(amount);
          });
      });

    globalStore.openSports
      .pipe(throttleTime(200), takeUntil(appDistory))
      .subscribe(getSports);
  };

  const checkUpdate = async () => {
    if (__DEV__ || !envConfig.codePushKey || globalStore.isWeb) {
      return;
    }
    try {
      remoteBundleRef.current = await CodePush.checkForUpdate(
        envConfig.codePushKey,
      );
      setTimeout(() => {
        startBackDownload(remoteBundleRef.current);
      }, 5000);
      // await checkRemoteBoundle(remoteBundleRef.current);
    } catch (error) {
      const {log} = console;
      log(error);
    }
  };

  const [chckedLang, setCheckLang] = React.useState(false);
  const {renderModal: renderLanguageModal, show: languageShow} =
    useLanguageModal({
      afterHidden: () => {
        setCheckLang(true);
        lanModalStatus.current = false;
      },
    });
  const checkLang = () => {
    globalStore.asyncGetItem('language').then(res => {
      if (res) {
        setCheckLang(true);
        globalStore.lang = res;
        i18n.changeLanguage(res);
      } else {
        languageShow();
        lanModalStatus.current = true;
      }
    });
  };

  const initApp = () => {
    setToken();
    setUserInfo();
    checkLang();
    globalSubscriptions();
    globalStore.asyncGetItem('last_record_time').then(res => {
      const timeCode = parseInt(res || '0', 10);
      if (
        !timeCode ||
        timeCode < new Date(new Date().toLocaleDateString()).getTime()
      ) {
        setTimeout(() => {
          dailyRecord();
          globalStore.asyncSetItem(
            'last_record_time',
            new Date().getTime() + '',
          );
        }, 600);
      }
    });
    if (globalStore.isAndroid) {
      checkUpdate();
      initChat();
      initPush();
      setTimeout(() => {
        versionModal.handleUpdate();
      }, 1000);
      const ReactMoE = require('react-native-moengage').default;
      ReactMoE.setEventListener('pushClicked', (notificationPayload: any) => {
        /**
         * 点击PUSH事件回调在此处处理, 注意测试下该回调是在前台触发还是后台接收触发
         */
        const {log} = console;
        log('pushClicked', notificationPayload);
      });
    }
  };

  const betbyRender = useMemo(() => {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'none',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        id="betby-container">
        <DetailNavTitle
          onBack={() => globalStore.betbyBackSubject.next('back')}
          hideServer
          title="Sports"
        />
        <div id="betby" style={{flex: 1, overflow: 'auto'}} />
      </div>
    );
  }, []);

  React.useEffect(() => {
    globalStore.asyncGetItem('channel').then(c => {
      globalStore.channel = c || defaultChannel;
      globalStore.asyncSetItem('hasChecked', globalStore.channel);
      initApp();
    });
    return () => {
      appDistory.next(true);
      appDistory.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startBackDownload = async (remoteBund: SafeAny) => {
    if (remoteBund) {
      downloadToastShow({
        type: 'weak',
        message: i18n.t('splash.tip.hasNew'),
      });
      downloadLock.current = true;
      const bundle = await remoteBund.download();
      await bundle.install(CodePush.InstallMode.ON_NEXT_RESTART);
      await CodePush.notifyAppReady();
      if (remoteBund.isMandatory) {
        confirmShow(
          i18n.t('splash.tip.alertTitle'),
          i18n.t('splash.tip.alertContent'),
          async () => {
            setTimeout(() => {
              CodePush.restartApp();
            }, 100);
          },
          () => {},
          true,
        );
      } else {
        downloadToastShow({
          type: 'weak',
          message: i18n.t('splash.tip.alertContent'),
        });
      }
    }
  };

  // const [list, setList] = React.useState<IListEle[]>([]);
  // const [popVisible, setPopVisible] = React.useState(false);
  // const [popImageRatio, setPopImageRatio] = React.useState(1);
  // const [popImage, setPopImage] = React.useState('');
  // const [popUrl, setPopUrl] = React.useState('');

  const versionModal = useVersionModal(
    true,
    versionInfo => {
      if (versionInfo) {
        // 如果需要更新,就不触发弹窗
        return;
      }
      // setTimeout(() => {
      //   if (isListPop) {
      //     checkPops().then(popListInfo => {
      //       if (popListInfo && popListInfo.length) {
      //         const popList = popListInfo.filter(v => v.status === 1);
      //         if (popList.length) {
      //           const transforList: IListEle[] = popList.map(v => ({
      //             popImg:
      //               'https://download.lotteryanna.com/manager/676d27d364344a14b4f042f44f3014cb.webp',
      //             status: v.status,
      //             popUrl: v.popUrl,
      //           }));
      //           for (let i = 0; i < popList.length; i++) {
      //             if (globalStore.isWeb) {
      //               const nImg = new window.Image();
      //               nImg.src = popList[i]?.popImg;
      //               nImg.onerror = () => {
      //                 popList[i].popImg =
      //                   'https://download.lotteryanna.com/manager/676d27d364344a14b4f042f44f3014cb.webp';
      //               };
      //             } else {
      //               // 没有进入回调说明加载失败
      //               Image.getSize(popList[i]?.popImg, () => {
      //                 transforList[i] = popList[i];
      //               });
      //             }
      //           }
      //           globalStore.isWeb ? setList(popList) : setList(transforList);
      //           setList(popList);
      //           trigglePop();
      //         }
      //       }
      //     });
      //   } else {
      //     checkPop().then(popInfo => {
      //       if (popInfo?.status === 1 && popInfo?.popImg) {
      //         Image.getSize(popInfo.popImg, (width, height) => {
      //           setPopImageRatio(height / width);
      //           setPopImage(popInfo.popImg);
      //           setPopUrl(popInfo.popUrl);
      //           trigglePop();
      //         });
      //       }
      //     });
      //   }
      // }, 1000);
    },
    false,
  );
  const checkLangRef = React.useRef(false);
  // const trigglePop = () => {
  //   // 直接延迟是因为为了避免被顶号的情况导致弹窗被带到login
  //   if (globalStore.isWeb) {
  //     const id = setInterval(() => {
  //       if (location.href.indexOf('/index/home') > -1 && checkLangRef.current) {
  //         clearInterval(id);
  //         !popVisible && setPopVisible(true);
  //       }
  //     }, 1000);
  //   }
  // };
  React.useEffect(() => {
    checkLangRef.current = chckedLang;
    if (chckedLang) {
      checkUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chckedLang]);
  // React.useEffect(() => {
  //   if (isListPop) {
  //     if (
  //       globalStore.isAndroid &&
  //       list.length &&
  //       list[0].popImg &&
  //       chckedLang
  //     ) {
  //       !popVisible && setPopVisible(true);
  //     }
  //   } else {
  //     if (globalStore.isAndroid && popImage && chckedLang) {
  //       !popVisible && setPopVisible(true);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [list, chckedLang, popImage]);

  return (
    <SafeAreaProvider style={[theme.position.rel]}>
      <React.Fragment>
        {Platform.OS === 'web' ? (
          <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
        ::-webkit-scrollbar{
          display: none;
        }
      `}</style>
        ) : null}
      </React.Fragment>
      <StatusBar barStyle="light-content" />
      <Spin loading={globalLoading}>
        <SafeAreaView
          style={[
            globalStore.isWeb &&
              ({
                height: screenHeight,
                width: '100%',
                marginHorizontal: 'auto',
              } as BasicObject),
            theme.flex.col,
            theme.overflow.hidden,
            theme.position.rel,
          ]}>
          <View style={[theme.fill.fill]}>
            <NavigationContainer
              onStateChange={() => {
                const previousRouteName = routeNameRef.current;
                const currentRoute = navigationRef!.getCurrentRoute()!.name;
                if (previousRouteName !== '') {
                  setCurrentRouteName(currentRoute);
                }
                routeNameRef.current = currentRouteName;
              }}
              ref={navigationRef}
              linking={linking}>
              <Stack.Navigator
                screenOptions={{
                  headerShown: false,
                  cardStyleInterpolator: globalStore.isWeb
                    ? undefined
                    : CardStyleInterpolators.forHorizontalIOS,
                }}>
                <Stack.Screen name="Index" component={MainNav} />
                {Object.values(routes).map(route => (
                  <Stack.Screen
                    key={route.name}
                    name={route.name}
                    component={route.component}
                    options={{headerShown: route.headerShown || false}}
                  />
                ))}
              </Stack.Navigator>
            </NavigationContainer>
            {betbyRender}
          </View>
        </SafeAreaView>
      </Spin>

      {/* <DialogLoading isVisible={globalLoading} /> */}
      {renderDownloadToast}
      {versionModal.versionModal.renderModal}
      {/* {isListPop && list.length > 0 && (
        <PromotionModal
          popVisible={popVisible}
          setPopVisible={setPopVisible}
          list={list}
        />
      )} */}
      {/* {!isListPop && (
        <Overlay
          isVisible={popVisible}
          overlayStyle={[theme.padding.zorro, theme.flex.centerByCol]}>
          <NativeTouchableOpacity
            onPress={() => {
              if (popUrl.startsWith('route:')) {
                const [route, popParams] = popUrl.slice(6).split('?');
                const needToken = tokenRoutes.some(r => route.startsWith(r));
                if (!needToken || (needToken && globalStore.token)) {
                  goTo(route, getUrlParams(popParams));
                } else {
                  goTo('Login', {
                    sucessPage: route,
                    sucessPageParams: popParams,
                  });
                }
              } else if (popUrl.startsWith('http')) {
                Linking.openURL(popUrl);
              } else {
                popUrl && goToUrl(popUrl);
              }
              setPopVisible(false);
            }}>
            <ImageBackground
              style={[
                {
                  width: screenWidth * 0.75,
                  height: screenWidth * 0.75 * popImageRatio,
                },
                theme.position.rel,
              ]}
              source={{uri: popImage}}
            />
          </NativeTouchableOpacity>
          <NativeTouchableOpacity
            style={{
              right: theme.paddingSize.xxl,
              top: theme.paddingSize.xxl,
            }}
            onPress={() => {
              setPopVisible(false);
            }}>
            <Image
              style={[theme.icon.xxl, theme.position.abs]}
              source={require('@assets/icons/home/button-close.png')}
            />
          </NativeTouchableOpacity>
        </Overlay>
      )} */}
      {renderLanguageModal}
      {renderConfirmModal}
      <SportModal<SportsModalProps>
        popVisible={popSports}
        setPopVisible={setPopSports}
        list={sports}
        getSportURL={getSportURL}
      />
      <ParabolaProvider />
    </SafeAreaProvider>
  );
}

if (globalStore.isAndroid) {
  let codePushOptions = {checkFrequency: CodePush.CheckFrequency.MANUAL};
  AppWithCodePush = CodePush(codePushOptions)(App);
}
if (globalStore.isWeb) {
  setupAdjust();
}

export default globalStore.isWeb ? App : AppWithCodePush;
